import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="data-imports-selection"
export default class extends Controller {
  static values = { buttonText: String };

  static targets = ["checkbox", "button", "input"];

  connect() {
    this.selectedFinancialDataMonthliesIds = new Set();
  }

  selectCheckbox(event) { 
    const financialDataMonthlyId = parseInt(event.currentTarget.dataset.financialDataMonthlyId);
    this.updateSelectedFinancialDataMonthliesIds(financialDataMonthlyId, event.currentTarget.checked);
    this.updateButton();
  }

  updateSelectedFinancialDataMonthliesIds(financialDataMonthlyId, isChecked) {
    if (isChecked) {
      this.selectedFinancialDataMonthliesIds.add(financialDataMonthlyId);
    } else {
      this.selectedFinancialDataMonthliesIds.delete(financialDataMonthlyId);
    }

    this.inputTarget.value = JSON.stringify(Array.from(this.selectedFinancialDataMonthliesIds));
  }

  updateButton() {
    const selectedBatchCount = this.selectedFinancialDataMonthliesIds.size;

    if (selectedBatchCount > 0) {
      this.buttonTarget.disabled = false;
      this.buttonTarget.textContent = `${this.buttonTextValue} (${selectedBatchCount})`;
      this.buttonTarget.classList.remove("btn-gray");
      this.buttonTarget.classList.add("btn-danger")
    } else {
      this.buttonTarget.disabled = true;
      this.buttonTarget.textContent = this.buttonTextValue;
      this.buttonTarget.classList.add("btn-gray");
      this.buttonTarget.classList.remove("btn-danger")
    }
  }
}
