
document.addEventListener('alpine:init', () => {
  Alpine.data('taxFormBuilder', () => ({
    lineItems: [],
    suggestions: [],
    currentInput: '',
    selectedSuggestionIndex: -1,
    unsavedChanges: false,
    newRecord: false,
    formulaSuggestionsRoutePath: '',
    formulaValidatorRoutePath: '',
    autocompleteRules: [
      {
        text: 'MISC_FIELD',
        description: 'Fetches miscellaneous data from the system and expects a category and a subcategory as arguments.',
        parameters: 2,
        example: 'MISC_FIELD(CATEGORY, SUBCATEGORY)'
      },
      {
        text: 'REF',
        description: 'Fetches data from previous line items and expects an identifier as an argument.',
        parameters: 1,
        example: 'REF(ROW-IDENTIFIER)'
      },
      {
        text: 'MANUAL_FIELD',
        description: 'Fetches data from manual fields and expects a field name as an argument. Optionally, you can pass a second argument to override the value.',
        parameters: 1,
        example: 'MANUAL_FIELD(SUGGESTED_FIELD_NAME, 50)'
      },
      {
        text: 'FINANCIAL_DATA',
        description: 'Fetches financial data and expects a subcategory and the value (e.g., taxable_sales, sales_tax_collected) needed to be fetched as arguments.',
        parameters: 2,
        example: 'FINANCIAL_DATA(SUBCATEGORY, VALUE)'
      },
    ],

    init() {
      const container = this.$refs.taxFormDataContainer;
      this.lineItems = JSON.parse(container.dataset.lineItems).map(item => ({
        ...item,
        isOpen: false,
        suggestions: []
      }));
      this.formulaSuggestionsRoutePath = container.dataset.formulaSuggestionsRoutePath;
      this.formulaValidatorRoutePath = container.dataset.formulaValidatorRoutePath;
      this.newRecord = container.dataset.newRecord === 'true';

      this.lineItems.forEach((_, index) => this.validateFormula(index));
    },

    validateFormula(index) {
      const formula = this.lineItems[index].formula;
      if (!formula) return;

      fetch(`${this.formulaValidatorRoutePath}?formula=${encodeURIComponent(formula)}`)
        .then(response => response.json())
        .then(data => {
          this.lineItems[index].isValid = data.valid;
          this.lineItems[index].errors = data.errors;
        });
    },

    findNearestUnclosedParen(text) {
      let unclosedParenCount = 0;
      let lastUnclosedParenIndex = -1;

      Array.from(text).forEach((char, i) => {
        if (char === '(') {
          unclosedParenCount++;
          lastUnclosedParenIndex = i;
        } else if (char === ')') {
          unclosedParenCount--;
        }
      });

      return { unclosedParenCount, lastUnclosedParenIndex };
    },

    fetchSuggestions(index, query) {
      const lineItem = this.lineItems[index];
      const triggerRegex = /(\w+\([^)]*,?\s*$)/
      const match = query.match(triggerRegex);

      if (!query.length || !match) {
        lineItem.suggestions = [];
        return;
      }

      const relevantQuery = match[1];
      const triggerMatch = relevantQuery.match(/(\w+)\(([^)]*)$/);

      if (!triggerMatch) {
        lineItem.suggestions = [];
        return;
      }

      const [, trigger, params] = triggerMatch;

      if (trigger === 'REF') {
        this.fetchRefSuggestions(lineItem, params);
      } else {
        this.fetchFunctionSuggestions(lineItem, trigger, params.trim());
      }
    },

    fetchRefSuggestions(lineItem, params) {
      const identifierInputs = Array.from(
        document.querySelectorAll('.tax-forms-line-items-identifier')
      );

      lineItem.suggestions = identifierInputs
        .map(input => {
          const item = input.value.replace('REF(', '').replace(')', '');
          return {
            text: item,
            description: `Reference to the item with identifier ${item}`,
            example: `REF(${item}`
          };
        })
        .filter(item =>
          item.text.length > 0 &&
            item.text.toLowerCase().startsWith(params.toLowerCase())
        );
    },

    fetchFunctionSuggestions(lineItem, trigger, params) {
      const url = `${this.formulaSuggestionsRoutePath}?function=${encodeURIComponent(trigger)}&options=${encodeURIComponent(params)}`;

      const fetchSuggestions = async () => {
        try {
          const response = await fetch(url);
          const data = await response.json();

          if (trigger === 'MISC_FIELD' && data.length > 1) {
            const [category] = params.split(',').map(p => p.trim());

            lineItem.suggestions = category ? [
              {
                text: 'All',
                description: `Add all ${category} subcategory options`,
                example: data.map(item => item.text).join(', ')
              },
              ...data
            ] : data;
          } else {
            lineItem.suggestions = data;
          }
        } catch (error) {
          lineItem.suggestions = [];
        }
      };

      fetchSuggestions();
    },

    updateSuggestions(index) {
      const formula = this.lineItems[index].formula || '';
      const lineItem = this.lineItems[index];
      const input = document.querySelector(
        `input[name='tax_form[line_items_attributes][${index}][formula]']`
      );
      const cursorPosition = input.selectionStart;
      const textBeforeCursor = formula.slice(0, cursorPosition);
      const lastWord = textBeforeCursor.split(/[^a-zA-Z_]+/).pop().toLowerCase();
      const unclosedFunctionPattern = /(\w+\([^)]*)$/;

      if (unclosedFunctionPattern.test(textBeforeCursor) || textBeforeCursor.trim().endsWith(',')) {
        this.fetchSuggestions(index, textBeforeCursor);
      } else {
        lineItem.suggestions = this.autocompleteRules.filter(rule =>
          rule.text.toLowerCase().startsWith(lastWord)
        );
      }
    },

    handleInput(event, index) {
      this.currentInput = event.target.value;
      this.updateSuggestions(index);
      this.validateFormula(index);
      this.unsavedChanges = true;
    },

    handleFocus(event, index) {
      this.currentInput = this.lineItems[index].formula || '';
      this.updateSuggestions(index);
    },

    handleClick(event, index) {
      this.cursorPosition = event.target.selectionStart;
      this.updateSuggestions(index);
    },

    handleKeyup(event, index) {
      this.cursorPosition = event.target.selectionStart;
      this.updateSuggestions(index);
    },

    addSuggestion(index, suggestion) {
      const inputElement = document.querySelector(
        `input[name='tax_form[line_items_attributes][${index}][formula]']`
      );
      const cursorPosition = inputElement.selectionStart;
      const currentFormula = this.lineItems[index].formula || '';
      const formulaBeforeCursor = currentFormula.slice(0, cursorPosition);
      const formulaAfterCursor = currentFormula.slice(cursorPosition);

      let matchingRule = this.autocompleteRules.find(rule => rule.text === suggestion);

      if (!matchingRule) {
        const lastFunctionCallMatch = formulaBeforeCursor.match(/(\w+)\([^)]*$/);
        const lastFunctionName = lastFunctionCallMatch ? lastFunctionCallMatch[1] : null;
        matchingRule = this.autocompleteRules.find(rule => rule.text === lastFunctionName);
      }

      const { unclosedParenCount, lastUnclosedParenIndex } = this.findNearestUnclosedParen(formulaBeforeCursor);
      const insideFunctionContext = unclosedParenCount > 0;
      const parameterCount = insideFunctionContext
        ? formulaBeforeCursor.slice(lastUnclosedParenIndex + 1).split(',').length
        : 0;

      const trailingWordPattern = /[\w]*$/;
      const updatedFormulaBeforeCursor = formulaBeforeCursor.replace(trailingWordPattern, '');

      let updatedFormula;
      let newCursorPos;

      if (matchingRule && parameterCount > 0) {
        if (parameterCount < matchingRule.parameters) {
          updatedFormula = `${updatedFormulaBeforeCursor}${suggestion},${formulaAfterCursor}`;
          newCursorPos = updatedFormulaBeforeCursor.length + suggestion.length + 1;
        } else {
          const isExactRule = this.autocompleteRules.find(rule => rule.text === suggestion);
          updatedFormula = `${updatedFormulaBeforeCursor}${isExactRule ? `${suggestion}(` : `${suggestion})`}${formulaAfterCursor}`;
          newCursorPos = updatedFormulaBeforeCursor.length + suggestion.length + (isExactRule ? 1 : 2);
        }
      } else if (matchingRule) {
        updatedFormula = `${updatedFormulaBeforeCursor}${suggestion}(${formulaAfterCursor}`;
        newCursorPos = updatedFormulaBeforeCursor.length + suggestion.length + 1;
      } else {
        updatedFormula = `${updatedFormulaBeforeCursor}${suggestion}${formulaAfterCursor}`;
        newCursorPos = updatedFormulaBeforeCursor.length + suggestion.length;
      }

      this.lineItems[index].formula = updatedFormula;
      this.lineItems[index].suggestions = [];

      this.$nextTick(() => {
        inputElement.focus();
        inputElement.setSelectionRange(newCursorPos, newCursorPos);
        inputElement.scrollLeft = inputElement.scrollWidth;
        this.updateSuggestions(index);
        this.unsavedChanges = true;
        this.validateFormula(index);
      });
    },

    handleKeyDown(event, lineIndex) {
      const { key } = event;
      const lineItem = this.lineItems[lineIndex];

      if (key === 'ArrowDown') {
        event.preventDefault();
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex + 1) % lineItem.suggestions.length;
      } else if (key === 'ArrowUp') {
        event.preventDefault();
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex - 1 + lineItem.suggestions.length) % lineItem.suggestions.length;
      } else if (key === 'Enter') {
        event.preventDefault();
        const isValidSelection = this.selectedSuggestionIndex >= 0 && lineItem.suggestions.length > 0;
        if (isValidSelection) {
          const selectedSuggestion = lineItem.suggestions[this.selectedSuggestionIndex].text;
          this.addSuggestion(lineIndex, selectedSuggestion);
        }
      }
    },

    moveItem(fromIndex, toIndex) {
      const [itemToMove] = this.lineItems.splice(fromIndex, 1);
      this.lineItems.splice(toIndex, 0, itemToMove);
      this.updatePositions();
      this.unsavedChanges = true;
    },

    moveUp(index) {
      if (index > 0) this.moveItem(index, index - 1);
    },

    moveDown(index) {
      if (index < this.lineItems.length - 1) this.moveItem(index, index + 1);
    },

    updatePositions() {
      this.lineItems.forEach((item, index) => {
        item.position = index;
      });
    },

    handleDrop(event, targetIndex) {
      event.preventDefault();
      const fromIndex = event.dataTransfer.getData('index');
      this.moveItem(fromIndex, targetIndex);
    },

    handleSave(event) {
      const form = document.querySelector('#template-form');
      if (!form.checkValidity()) {
        event.preventDefault();
        form.reportValidity();
      }
    },

    handleSaveAndContinueEditing(event) {
      event.preventDefault();
      const form = document.querySelector('#template-form');
      if (!form.checkValidity()) {
        form.reportValidity();
        return;
      }

      this.$refs.continueEditing.value = true;
      if (this.newRecord) {
        form.submit();
      } else {
        Turbo.navigator.submitForm(form);
        this.unsavedChanges = false;
        this.$refs.saveButton.removeAttribute('disabled');
      }
    },

    addLineItem() {
      this.lineItems.push({
        description: '',
        formula: '',
        position: this.lineItems.length,
        field_type: '',
      });
      this.updatePositions();
      this.$nextTick(() => {
        this.lineItems = [...this.lineItems];
      });

      if (!this.newRecord && this.unsavedChanges) {
        const form = document.querySelector('#template-form');
        this.$refs.continueEditing.value = true;
        Turbo.navigator.submitForm(form);

        this.unsavedChanges = false;
      }
    }
  }));
});