import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  static targets = ['accountNumberInput'];

  connect() {
    this.selectedAllCheckbox = false;
    this.uncheckSelectAll = false;

    this.tomSelectInstance = new TomSelect('#ecommerce-report-add', {
      plugins: ['checkbox_options'],
      render: {
        option: this.renderOption,
        item: (data, escape) => {
          if (this.selectedAllCheckbox) {
            return this.renderOption(data, escape, `ecommerce-reporting-option ${data.value !== 'all' && 'hidden'}`);
          }

          return this.renderOption(data, escape, 'ecommerce-reporting-option');
        },
      },
      _onItemAdd: (value, item) => {
        const { items, options } = this.tomSelectInstance;

        if (value === 'all') {
          this.selectedAllCheckbox = !this.selectedAllCheckbox;
          this.toggleAccountNumberInput(items);

          const itemsSet = new Set(items);
          const keysToAdd = Object.keys(options).filter((key) => key !== 'all' && !itemsSet.has(key));
          keysToAdd.forEach((key) => this.tomSelectInstance.addItem(key));

          this.toggleOptionItems();
          this.toggleManualInput();
        } else {
          this.toggleAccountNumberInput(items);

          const allOptionsSelected = items.length === this.totalOptionsCount;
          this.toggleOptionItems(!allOptionsSelected);

          if (allOptionsSelected) {
            this.tomSelectInstance.addItem('all');
            this.toggleManualInput();
          }
        }
      },
      get onItemAdd() {
        return this._onItemAdd;
      },
      set onItemAdd(value) {
        this._onItemAdd = value;
      },
      onItemRemove: (value, item) => {
        const { items } = this.tomSelectInstance;
        this.toggleManualInput(true);
        this.toggleAccountNumberInput(items);

        if (value == 'all' && !this.uncheckSelectAll) {
          this.clearAllSelections(this.tomSelectInstance);
        } else if (items.includes('all')) {
          this.uncheckSelectAll = true;
          this.tomSelectInstance.removeItem('all');
          this.toggleOptionItems(true);
          this.uncheckSelectAll = false;
        }
      },
    });

    this.totalOptionsCount = Object.keys(this.tomSelectInstance.options).length - 1; // exclude 'all' option
  }

  toggleAccountNumberInput(items) {
    this.accountNumberInputTarget.toggleAttribute('disabled', items.length > 1);
  }

  clearAllSelections() {
    this.selectedAllCheckbox = !this.selectedAllCheckbox;
    this.tomSelectInstance.clear(true);
  }

  toggleOptionItems(show = false) {
    document.querySelectorAll('.ecommerce-reporting-option.item').forEach((option) => {
      if (option.dataset.value !== 'all') {
        show ? option.classList.remove('!hidden') : option.classList.add('!hidden');
      }
    });
  }

  toggleManualInput(show = false) {
    const $ecommerceReportAdd = document.querySelector('#ecommerce-report-add-ts-control');
    show ? $ecommerceReportAdd.classList.remove('invisible') : $ecommerceReportAdd.classList.add('invisible');
  }

  renderOption(data, escape, className = '') {
    return `<div class="${className}">
    <span>${escape(data.text)}</span>
  </div>`;
  }
}
