import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.data('reportTemplateField', () => ({
    compilers: [],
    route: '',
    isLoading: false,
    isAddingMissing: false,
    mappings: [],
    init() {
      this.compilers = JSON.parse(this.$el.dataset.compilers);
      this.mappings = JSON.parse(this.$el.dataset.mappings || '[]');
      this.route = this.$el.dataset.aiDiscoveryRoute;

      if (this.mappings.length === 0) {
        this.addMapping();
      }
    },
    addMapping() {
      const lastMapping = this.mappings[this.mappings.length - 1];
      if (lastMapping) {
        const currentIndex = this.compilers.findIndex(c => c.name === lastMapping.compiler);
        const nextCompiler = this.compilers[currentIndex + 1]?.name || this.compilers[0]?.name || '';
        this.mappings.push({ compiler: nextCompiler, field: '' });
      } else {
        this.mappings = [{ compiler: this.compilers[0]?.name || '', field: '' }];
      }
    },
    removeMapping(index) {
      this.mappings.splice(index, 1);
    },
    getFields(compilerName) {
      return this.compilers.find(c => c.name === compilerName)?.fields || [];
    },
    refreshFieldOptions() {
      this.$nextTick(() => {
        this.mappings.forEach(mapping => {
          if (mapping.compiler) {
            const availableFields = this.getFields(mapping.compiler);
            if (!availableFields.includes(mapping.field)) {
              mapping.field = '';
            }
          } else {
            mapping.field = '';
          }
        });
      });
    },
    prepareWithAI() {
      this.isLoading = true;
      fetch(this.route)
        .then(response => response.json())
        .then(data => {
          if (data.suggestions) {
            const existingMappings = new Map(this.mappings.map(m => [m.compiler, m]));

            Object.entries(data.suggestions).forEach(([compiler, field]) => {
              if (existingMappings.has(compiler)) {
                if (!existingMappings.get(compiler).field) {
                  existingMappings.get(compiler).field = field || '';
                }
              } else {
                this.mappings.push({ compiler: compiler, field: field || '' });
              }
            });
          }
        })
        .catch(error => console.error('Error:', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    addMissingCompilers() {
      this.isAddingMissing = true;
      const existingCompilers = new Set(this.mappings.map(m => m.compiler));
      const missingCompilers = this.compilers.filter(c => !existingCompilers.has(c.name));

      this.$nextTick(() => {
        missingCompilers.forEach(compiler => {
          this.mappings.push({ compiler: compiler.name, field: '' });
        });

        this.$nextTick(() => {
          this.isAddingMissing = false;
        });
      });
    }
  }))
})