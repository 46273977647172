import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["field", "submit"];

  connect() {
    this.initializeFields();
    this.resetInitialState();
    this.checkForChanges();
  }

  initializeFields() {
    this.fieldTargets.forEach((field) => {
      const defaultValue = field.dataset.default;

      if (field.type === 'checkbox') {
        field.checked = defaultValue === 'true';
      } else {
        field.value = defaultValue;
      }

      field.addEventListener("change", (event) => {
        field.dataset.modified = true;
        this.checkForChanges();
      });
    });
  }

  resetInitialState() {
    this.fieldTargets.forEach((field) => {
      field.removeAttribute("disabled");
    });

    this.submitTargets.forEach((target) => {
      target.disabled = true;
    });
  }

  beforeSubmit() {
    this.fieldTargets.forEach((field) => {
      if (field.dataset.modified == 'true') {
        field.removeAttribute("disabled");
        return;
      }

      field.setAttribute("disabled", "true");
    });
  }

  afterSubmit() {
    this.resetInitialState();
    this.checkForChanges();
  }

  checkForChanges() {
    const hasChanges = this.fieldTargets.some((field) => field.dataset.modified === "true");
    this.submitTargets.forEach((target) => {
      target.disabled = !hasChanges;
    });
  }
}
