import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "customReportNameContainer", "customReportName", "submitButton", "all"]
  static values = {
    parentFieldSelector: String
  }

  connect() {
    this.updateAllCheckbox()
  }

  toggleParent(event) {
    const parentCheckbox = event.target
    const parentName = parentCheckbox.value.toLowerCase().replace(/[^a-z0-9]+/g, '_')
    this.fieldTargets
      .filter(field => field.dataset.parent === parentName)
      .forEach(child => child.checked = parentCheckbox.checked)
    this.updateAllCheckbox()
  }

  toggleAll() {
    const checked = this.allTarget.checked
    this.fieldTargets.forEach(checkbox => checkbox.checked = checked)
  }

  updateAllCheckbox() {
    if (this.hasAllTarget) {
      this.allTarget.checked = this.fieldTargets.every(checkbox => checkbox.checked)
    }
  }

  toggleCustomReportName(event) {
    const isChecked = event.target.checked
    this.customReportNameContainerTarget.style.display = isChecked ? "block" : "none"
    this.customReportNameTarget.required = isChecked
    this.updateButtonText()
  }

  updateButtonText() {
    const isVisible = this.customReportNameContainerTarget.style.display !== "none"
    this.submitButtonTarget.value = isVisible ? "Create and Save Custom Report" : "Create Custom Report"
  }

  fieldTargetConnected(field) {
    this.updateAllCheckbox()
  }

  fieldTargetDisconnected(field) {
    this.updateAllCheckbox()
  }
}